import React from "react";
import styles from "./ArticleTeaser.module.scss";
import ApiImage from "../../../../cms_content/components/global/ApiImage/index";
import ArticleAuthorLib from "../../../libs/ArticleAuthorLib";
import GeneralUtility from "../../../../../libs/GeneralUtility";
import OptionalLinkWrapper from "../../../../../components/OptionalLinkWrapper";

const ArticleTeaser = ({
  title,
  altText,
  layout = "layoutDefault",
  sizeLarge = "3",
  sizeMedium = "6",
  sizeSmall = "12",
  additionalClass,
  teaserText,
  imageSrc,
  imageCopyright,
  tag,
  authorsHeadlinePrefix,
  authors,
  linkHref,
  linkAs,
  disableLink = false,
  articleDetails = false,
  duration,
}) => {
  const renderHeadline = (rawHTML, className = "", tag) => {
    const tagSize = sizeLarge >= 6 ? styles.largeTag : styles.tag;
    if (articleDetails) {
      return (
        <h1 className={className}>
          {tag && (
            <>
              <span className={tagSize}>{tag}</span>
            </>
          )}
          <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
        </h1>
      );
    } else {
      return (
        <h3 className={className}>
          {tag && (
            <>
              <span className={tagSize}>{tag}</span>
            </>
          )}
          <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
        </h3>
      );
    }
  };

  // open external article links in new tab
  let linkTarget = null;
  if (linkHref && linkHref.startsWith("http")) {
    linkTarget = "_blank";
  }

  return (
    <div
      className={`cell small-${sizeSmall} medium-${sizeMedium} large-${sizeLarge} layout-${layout} ${
        articleDetails && styles.articlesDetails
      } ${additionalClass}`}
    >
      <div className={`${styles.article} ${styles[layout]}`}>
        <OptionalLinkWrapper href={linkHref} target={linkTarget}>
          {imageSrc &&
            layout !== "layoutDefault" &&
            ["layoutImageTop", "layoutImageTopWithoutHover"].includes(
              layout
            ) && (
              <div className="ce-ratio-box ce-ratio-box--16-9">
                <figure className="ce-ratio-box__content">
                  <picture>
                    <ApiImage
                      src={imageSrc}
                      className={styles.articleImg}
                      nextOptimizedImage={true}
                      layout={"fill"}
                      alt={altText}
                    />
                  </picture>
                </figure>
              </div>
            )}

          {articleDetails ? (
            <div className={styles.layoutArticleDetails}>
              <div
                className={`${!disableLink ? "text-wrapper" : ""} ${
                  sizeLarge >= 6
                    ? styles.largeLink
                    : styles.link
                }`}
              >
                {tag && (
                  <span
                    className={
                      sizeLarge >= 6
                        ? `${styles.articleDetails_tag}`
                        : styles.tag
                    }
                  >
                    {tag}
                  </span>
                )}
                {title &&
                  renderHeadline(
                    title,
                    sizeLarge >= 6
                      ? styles.largeTitle
                      : styles.title
                  )}
                {teaserText && (
                  <div
                    className={styles.teaserText}
                    dangerouslySetInnerHTML={{
                      __html: GeneralUtility.removeHtmlTags(teaserText),
                    }}
                  />
                )}
                {authors && Array.isArray(authors) && authors.length > 0 && (
                  <span className={styles.author}>
                    {authorsHeadlinePrefix}{" "}
                    {ArticleAuthorLib.getAllAuthorsString(authors)}
                  </span>
                )}
              </div>
              {imageCopyright && (
                <div
                  className={styles.copyright}
                  dangerouslySetInnerHTML={{ __html: imageCopyright }}
                />
              )}
            </div>
          ) : (
            <div
              className={`${!disableLink ? "text-wrapper" : ""} ${
                sizeLarge >= 6
                  ? styles.largeLink
                  : styles.link
              }`}
            >
              {tag && (
                <span
                  className={
                    sizeLarge >= 6
                      ? styles.largeTag
                      : styles.tag
                  }
                >
                  {tag}
                </span>
              )}
              {renderHeadline(
                title,
                sizeLarge >= 6
                  ? styles.largeTitle
                  : styles.title
              )}
              <div
                className={styles.teaserText}
                dangerouslySetInnerHTML={{
                  __html: GeneralUtility.removeHtmlTags(teaserText),
                }}
              />

              {authors && Array.isArray(authors) && authors.length > 0 && (
                <span className={styles.author}>
                  {authorsHeadlinePrefix}{" "}
                  {ArticleAuthorLib.getAllAuthorsString(authors)}
                </span>
              )}
              {articleDetails && (
                <span
                  className={styles.reading}
                >{`Lesedauer: ${duration} Minuten`}</span>
              )}
            </div>
          )}

          {imageSrc &&
            ![
              "layoutDefault",
              "layoutImageTop",
              "layoutImageTopWithoutHover",
            ].includes(layout) && (
              <ApiImage
                src={imageSrc}
                className={styles.articleImg}
                nextOptimizedImage={true}
                layout={"fill"}
                alt={altText}
              />
            )}
        </OptionalLinkWrapper>
      </div>
    </div>
  );
};

export default ArticleTeaser;
