const getFirstName = (author) => {
  if (author.firstName) {
    return author.firstName;
  }

  if (author.autor) {
    return author.autor.firstName;
  }

  return "";
};

const getLastName = (author) => {
  if (author.lastName) {
    return author.lastName;
  }

  if (author.autor) {
    return author.autor.lastName;
  }

  return "";
};

const getFullName = (author) => {
  if (author.name) {
    return author.name;
  }

  return "";
};

const getImage = (author) => {
  if (author.image) {
    return author.image;
  }

  return "";
};

const getAuthorId = (author) => {
  if (author.autor && "uid" in author.autor && author.autor.uid) {
    return author.autor.uid;
  }

  return "";
};

const getDescription = (author) => {
  if (author.description && "description" in author && author) {
    return author.description;
  }

  return "";
};

const getAllAuthorsString = (authors) => {
  let authorsString = "";

  for (let author of authors) {
    authorsString += authorsString.length > 0 ? " und " : "";
    if (getFullName(author)) {
      authorsString += getFullName(author);
    } else {
      authorsString += `${getFirstName(author)} ${getLastName(author)}`;
    }
  }
  return authorsString;
};

export default {
  getFirstName,
  getLastName,
  getImage,
  getAuthorId,
  getAllAuthorsString,
  getDescription,
};
